import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page.js";
import parkConceptImage from '../images/tvf-centennial-park-concept.jpg';
import parkOpeningImage from '../images/tvf-centennial-park-opening.jpg';
import parkNov11Image from '../images/vimy-memorial-nov-11.jpg';
import parkFutureImage from '../images/tvf-centennial-park-2040-concept.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Figure = makeShortcode("Figure");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Features of the Centennial Park`}</h2>
    <Row mdxType="Row">
  <Column mdxType="Column">
    <p>
      The design was done by Linda Dicaire, Landscape Architect and VOLC
      volunteer.
    </p>
    <ul>
      <li>4 sided field covering 1.8 hectares.</li>
      <li>
        Low circular wall and hedges enclosing a 100m diameter Vimy Oaks grove
        symbolizing 100 years (1917-2017) of remembrance with circular paths
        providing universal access and seating.
      </li>
      <li>
        The grove: 4 concentric circles of trees forming allées : 1 circle for
        each Canadian Division who fought at Vimy.
      </li>
      <li>Large circular gathering place at the heart of the grove.</li>
      <li>Path with axial view to the Vimy Memorial (view corridor).</li>
      <li>
        Symbolic of a journey from darkness, death and despair (narrow) to
        light, life and hope (wide).
      </li>
      <li>
        View corridor captures the morning sun rising behind the Vimy Memorial
        on November 11th.
      </li>
      <li>
        Statue of Bugler donated by Canadian sculpter Marlene Hilton Moore
        stands beside the View Corridor.
      </li>
      <li>
        Welcome area outside wall with pairs of Vimy Oaks representing the
        friendships formed between soldiers and between nations.
      </li>
      <li>In Memoriam and Donor recognition wall.</li>
      <li>
        Paved apron on D55(road) invites pedestrians and permits links to a
        future Liaison Douce (pathway) from Givenchy-en-Gohelle.
      </li>
    </ul>
  </Column>
  <Column mdxType="Column">
    <Figure image={parkConceptImage} width={500} mdxType="Figure">
      Centennial Park Concept Design
    </Figure>
    <Figure image={parkOpeningImage} width={500} mdxType="Figure">
      Vimy Foundation Centennial Park Opening Nov 9 2018. View Corridor Points
      to the memorial.
    </Figure>
  </Column>
    </Row>
    <hr></hr>
    <Row mdxType="Row">
  <Column mdxType="Column">
    <Figure image={parkNov11Image} width={500} mdxType="Figure">
      November 11: sunrise looking down view corridor captures the Memorial and
      Bugler statue.
    </Figure>
  </Column>
  <Column mdxType="Column">
    <Figure image={parkFutureImage} width={500} mdxType="Figure">
      Artist’s concept 2040: mature Vimy Oaks and the view corridor to the
      memorial.
    </Figure>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      